import "tailwindcss/tailwind.css"
import VueI18n from 'vue-i18n'
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Home from './views/Home.vue';
import ThankYou from './views/ThankYou.vue';
import VueGtm from '@gtm-support/vue2-gtm'

Vue.config.productionTip = false

Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(VueGtm, {id: 'GTM-M4LTM6G'});

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale:'en',
  messages: {
    en: require('./locales/en.json'),
    my: require('./locales/my.json'),
    // Add other languages as needed
  },
});

const routes = [
  {
    path: '/:locale',
    component: {
      render(c) { return c('router-view'); },
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: 'thank-you', // Add a route for the Thank You page
        name: 'thankYou',
        component: ThankYou,
      },
      // Add other routes as needed
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const locale = to.params.locale;
  if (!['en', 'my'].includes(locale)) {
    return next({ name: 'home', params: { locale: 'en' } });
  }
  i18n.locale = locale;
  return next();
});

new Vue({
  render: (h) => h(App),
  i18n,
  router,
}).$mount('#app');
