<template>
  <div>
    <WaBtn />
    <MainBanner />

    <!-- Product -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:items-end md:justify-between">
          <div class="mb-4 md:mb-0 md:w-2/3">
            <h1
              class="inline-block px-5 py-1 mb-4 text-sm font-medium text-left bg-white border border-gray-100 shadow-sm lg:text-lg md:text-base text-teeth-darkblue">
              {{ $t('service_tagline') }}
            </h1>
            <h1 class="text-2xl font-semibold leading-tight lg:text-3xl text-teeth-darkblue">
              {{ $t('service_tagline_2') }}
            </h1>
          </div>
          <div class="md:w-1/3">
            <h1
              class="w-40 p-2 text-center text-white transition duration-300 ease-linear transform rounded md:ml-auto hover:-translate-y-2 bg-teeth-darkblue">
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">
                {{ $t('btn_book') }}
              </a>
            </h1>
          </div>
        </div>

        <!-- Braces Section -->
        <div class="mt-10">
          <carousel :autoplay="true" :autoplaySpeed="3000" :loop="true"
            :responsive="{ 0: { items: 1, nav: false, dots: true }, 768: { items: 2, nav: true }, 1024: { items: 3, nav: false } }">
            <div v-for="(item, i) in braces" :key="i" :index="i">
              <div class="border-2 border-gray-200 md:mx-2 bg-purple-50 rounded-2xl">
                <img :src="item.image" :alt="item.alt" class="!w-24 py-10 mx-auto" />
                <div class="px-4 py-6 text-center text-gray-600 bg-white rounded-b-2xl">
                  <h1 class="mb-3 text-lg font-semibold text-purple-900">
                    {{ $t(item.alt) }}
                  </h1>
                  <p class="text-sm">
                    {{ $t(item.p) }}
                  </p>
                </div>
              </div>
            </div>
          </carousel>
          <div class="pt-3">
            <h1
              class="w-40 p-2 px-3 mx-auto text-center text-white transition duration-300 ease-linear rounded-lg bg-wa-green">
              <a
                href="https://wa.me/60125281769?text=I%20would%20like%20to%20make%20an%20appointment/know%20more%20about%20your%20services.">
                <i class="pr-1 fa-brands fa-whatsapp"></i>{{ $t('btn_whatsapp') }}
              </a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="py-10">
      <div class="container">
        <div class="mb-4">
          <h1
            class="inline-block p-1 px-3 mb-4 text-sm font-medium text-center bg-white border border-gray-100 shadow-sm lg:text-lg md:text-base text-teeth-darkblue">
            {{ $t('other_service_tagline') }}
          </h1>
          <h1 class="text-2xl font-semibold leading-tight lg:text-3xl text-teeth-darkblue">
            {{ $t('other_service_tagline_2') }}<br class="hidden md:block" />
            {{ $t('other_service_tagline_3') }}
          </h1>
        </div>
        <div class="flex flex-wrap mt-5 lg:mt-10 md:justify-center">
          <div v-for="(item, i) in others" :key="i"
            class="w-1/2 p-2 text-center text-gray-600 transition duration-300 ease-linear md:w-1/4 hover:text-teeth-darkblue">
            <img :src="item.image" :alt="item.alt" class="w-24 mx-auto" />
            <h1 class="mt-4 text-sm font-semibold">{{ $t(item.alt) }}</h1>
          </div>
        </div>
        <div class="pt-3">
          <h1 class="w-40 p-2 mx-auto text-center text-white transition duration-300 ease-linear rounded-lg bg-wa-green">
            <a
              href="https://wa.me/60125281769?text=I%20would%20like%20to%20make%20an%20appointment/know%20more%20about%20your%20services.">
              <i class="pr-1 fa-brands fa-whatsapp"></i>{{ $t('btn_whatsapp') }}
            </a>
          </h1>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/choose-bg.jpg')">
      <div class="container">
        <h1 class="pb-3 text-2xl font-bold text-center text-white">
          {{ $t('why_choose_tagline') }}
        </h1>
        <div class="w-10 h-0.5 mx-auto bg-white"></div>

        <div class="flex flex-wrap pt-8">
          <div v-for="(item, i) in choose" :key="i" class="w-1/2 p-2 md:w-1/3">
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <div class="pt-4 text-center text-white">
              <h1 class="pb-5 text-base font-bold leading-tight">
                {{ $t(item.alt) }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-10">
      <div class="container">
        <div class="mb-4">
          <h1
            class="inline-block px-3 py-1 mb-4 text-sm font-medium text-center bg-white border border-gray-100 shadow-sm lg:text-lg md:text-base text-teeth-darkblue">
            {{ $t('team_tagline') }}
          </h1>
          <h1 class="text-2xl font-semibold leading-tight lg:text-3xl text-teeth-darkblue">
            {{ $t('team_tagline_2') }}
          </h1>
        </div>

        <div class="lg:flex">
          <!-- profile doc -->
          <div class="lg:w-1/2 lg:px-1">
            <div class="p-5 text-white shadow bg-teeth-darkblue rounded-xl">
              <img src="/images/doc-2.jpg" alt="" class="w-2/3 mx-auto md:w-1/2" />
              <h1 class="text-2xl font-semibold uppercase">DR NOREEN</h1>
              <div class="mt-4 text-sm">
                <p>
                  {{ $t('team_detail_1') }}
                </p>
              </div>
            </div>
          </div>
          <div class="mb-5 lg:w-1/2 lg:mb-0 lg:px-1">
            <div class="p-5 text-white shadow bg-teeth-darkblue rounded-xl">
              <img src="/images/doc-1.jpg" alt="" class="w-2/3 mx-auto md:w-1/2" />
              <h1 class="mt-2 text-2xl font-semibold uppercase">Dr Nazaneen</h1>
              <div class="mt-4 text-sm">
                <p class="mb-2">
                  {{ $t('team_detail_2') }}
                </p>
                <p class="mb-2">
                  {{ $t('team_detail_2_2') }}
                </p>
                <p>
                  {{ $t('team_detail_2_3') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div class="py-10 bg-purple-100">
      <div class="container">
        <div class="mb-8">
          <h1
            class="w-32 p-1 mb-4 text-sm font-medium text-center text-white border border-gray-100 shadow-sm lg:w-44 lg:text-lg md:w-36 md:text-base bg-teeth-darkblue">
            {{ $t('testimonial_tagline') }}
          </h1>
          <h1 class="text-2xl font-semibold leading-tight lg:text-3xl text-teeth-darkblue">
            {{ $t('testimonial_tagline_2') }}
          </h1>
          <p class="mt-3 text-gray-600 lg:text-xl">
            {{ $t('testimonial_tagline_3') }}
          </p>
        </div>
        <div class="md:w-3/4 md:mx-auto">
          <carousel :autoplay="true" :loop="true" :responsive="{
            0: { items: 1, nav: false, dots: true },
          }">
            <div v-for="(item, i) in testimonials" :key="i">
              <div class="mb-5 text-2xl text-center text-yellow-300">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
              </div>
              <p class="text-sm text-gray-600 lg:text-base">
                {{ item.p }}
              </p>
              <h1 class="mt-5 text-xl italic font-semibold text-center text-teeth-darkblue">
                - {{ item.client }} -
              </h1>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- Our Clinic -->
    <div class="py-10">
      <div class="container">
        <div class="mb-4">
          <h1
            class="inline-block p-1 px-3 mb-4 text-sm font-medium text-center bg-white border border-gray-100 shadow-sm lg:text-lg md:text-base text-teeth-darkblue">
            {{ $t('team_tagline') }}
          </h1>
          <h1 class="text-2xl font-semibold leading-tight lg:text-3xl text-teeth-darkblue">
            {{ $t('gallery_tagline') }}
          </h1>
        </div>

        <div class="mt-8">
          <carousel :autoplay="true" :loop="true" :responsive="{
            0: { items: 1, nav: false, dots: true },
            768: { items: 3, nav: false, dots: true },
          }">
            <div v-for="image in gallery" :key="image" class="md:px-1">
              <img :src="image" alt="" class="rounded-md shadow" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <div class="py-10">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="mb-4 lg:w-1/2 lg:pr-4 lg:mb-0">
            <h1 class="text-2xl font-bold text-teeth-darkblue">{{ $t('about_tagline') }}</h1>
            <div class="mt-6 text-sm text-gray-600">
              <p>
                {{ $t('about_1') }}
                <br /><br />
                {{ $t('about_2') }}
                <br /><br />
                {{ $t('about_3') }}
              </p>
            </div>
          </div>
          <div class="lg:w-1/2">
            <img src="/images/about-1.jpg" alt="" class="mx-auto md:w-2/3 lg:w-full" />
          </div>
        </div>
      </div>
    </div>

    <!-- enquiry /faq -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <!-- faq -->
          <div class="lg:w-1/2">
            <h1 class="mb-6 text-2xl font-bold text-center lg:text-left text-teeth-darkblue">
              FAQ
            </h1>
            <Accordion :faq="faq" :focus="false" />
          </div>
          <!-- enquiry -->
          <div id="element" class="pt-5 lg:pt-0 lg:px-2 lg:w-1/2">
            <div
              class="px-5 py-8 text-left border-2 shadow-lg text-teeth-darkblue md:w-2/3 lg:w-full lg:mx-0 md:mx-auto border-teeth-darkblue">
              <h1 class="text-5xl font-light">
                <span class="font-bold">{{ $t('form_1') }}</span> <br />
                {{ $t('form_2') }}
              </h1>
              <p class="pt-4 text-base">
                {{ $t('form_3') }}
              </p>

              <div class="pt-4">
                <!-- feedback.activamedia.com.sg script begins here -->
                <iframe allowTransparency="true" style="height: 500px; overflow: auto" width="100%" id="contactform123"
                  name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
                  src="//feedback.activamedia.com.sg/my-contact-form-5575412.html"></iframe>
                <!-- feedback.activamedia.com.sg script ends here -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <footer class="py-10 bg-teeth-darkblue">
      <div class="container">
        <div class="lg:flex lg:items-center">
          <div class="lg:w-1/2">
            <img src="/images/main-logo-white.png" alt="Smileland Dental Clinic"
              class="w-2/3 mx-auto md:w-1/3 lg:w-1/2" />
            <div class="pt-5">
              <a href="https://goo.gl/maps/E5gNNiCxAGpTkBab7">
                <p class="text-base font-semibold text-center text-white lg:text-xl">
                  32-1, Jalan Awf 1, Ampang Waterfront, 68000 Ampang, Selangor,
                  Malaysia
                </p>
              </a>
              <p class="py-4 text-base font-semibold text-center text-white lg:text-lg">
                <span class="lg:text-xl">{{ $t('footer_1') }}</span><br />
                {{ $t('footer_2') }} : 9.30am - 9.00pm <br />
                {{ $t('footer_3') }} : 9.00am - 9.00pm <br />
                {{ $t('footer_4') }} : 9.30am - 9.00pm <br />
                {{ $t('footer_5') }} : 9.00am - 7.00pm <br />
                {{ $t('footer_6') }} : 9.30am - 9.00pm <br />
                {{ $t('footer_7') }} : 9.30am - 5.00pm
              </p>
              <p class="pb-4 text-lg font-semibold text-center text-white lg:text-xl">
                {{ $t('footer_8') }} :
                <a href="tel:+60125281769" id="call-btn-bottom">012-5281769</a>
              </p>
            </div>
          </div>
          <div class="lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1991.902850482409!2d101.7692039!3d3.1459186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc36e6ad4e0dd1%3A0x90b308bb96c256e6!2sKlinik%20Pergigian%20Idramawar%20%40%20ampang!5e0!3m2!1sen!2smy!4v1656487142933!5m2!1sen!2smy"
              width="100%" height="350" style="border: 0" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </footer>

    <!-- Copyright -->
    <div class="py-3 bg-white">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-teeth-darkblue lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation,
} from "hooper";
import "hooper/dist/hooper.css";
import WaBtn from "@/components/WaBtn.vue";
import Accordion from "@/components/Accordion.vue";
import carousel from "vue-owl-carousel";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    Accordion,
    carousel,
    WaBtn,
    MainBanner,
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: false,
        wheelControl: false,
        autoPlay: true,
        playSpeed: 4500,
        infiniteScroll: true,
        breakpoints: {
          768: {
            itemsToShow: 2,
          },
          1024: {
            itemsToShow: 3,
          },
        },
      },
      braces: [
        {
          image: "/images/brace-1.png",
          alt: "service_1",
          p: "service_1_p",
        },
        {
          image: "/images/brace-2.png",
          alt: "service_2",
          p: "service_2_p",
        },
        {
          image: "/images/brace-3.png",
          alt: "service_3",
          p: "service_3_p",
        },
        {
          image: "/images/brace-4.png",
          alt: "service_4",
          p: "service_4_p",
        },
        {
          image: "/images/brace-5.png",
          alt: "service_5",
          p: "service_5_p",
        },
        {
          image: "/images/brace-6.png",
          alt: "service_6",
          p: "service_6_p",
        },
      ],
      others: [
        { image: "/images/other-1.png", alt: "other_service_service_1" },
        { image: "/images/other-2.png", alt: "other_service_service_2" },
        { image: "/images/other-3.png", alt: "other_service_service_3" },
        { image: "/images/other-4.png", alt: "other_service_service_4" },
        { image: "/images/other-5.png", alt: "other_service_service_5" },
        { image: "/images/other-6.png", alt: "other_service_service_6" },
        { image: "/images/other-7.png", alt: "other_service_service_7" },
        { image: "/images/other-8.png", alt: "other_service_service_8" },
        {
          image: "/images/other-9.png",
          alt: "other_service_service_9",
        },
        { image: "/images/other-10.png", alt: "other_service_service_10" },
        { image: "/images/other-11.png", alt: "other_service_service_11" },
      ],
      choose: [
        {
          image: "/images/icon-4.png",
          alt: "choose_1",
        },
        {
          image: "/images/icon-1.png",
          alt: "choose_2",
        },
        {
          image: "/images/icon-3.png",
          alt: "choose_3",
        },
        {
          image: "/images/icon-6.png",
          alt: "choose_4",
        },
        {
          image: "/images/icon-5.png",
          alt: "choose_5",
        },
        {
          image: "/images/icon-2.png",
          alt: "choose_6",
        },
      ],
      testimonials: [
        {
          p: "1st time to this clinic. I was attended by Dr sonia and the female assistant. Both was very attentive and showed me of their concern as I was very nervous to extract my miserable teeth. I will come back... Like their service very much. Will try again for the 2nd time.",
          client: "Zainun Rose",
        },
        {
          p: " Second time/year visiting this clinic. Friendly nurses and attentive dentist. Prices are also reasonable. I highly recommend this clinic to others.",
          client: "Kit Meng",
        },
        {
          p: "excellent service. the dentist is very thorough and took time to explain and give the advice needed.",
          client: "Maya Sekeran",
        },
        {
          p: "Terbaik... puas hati dgn kerja Doktor... lapang je mulut lepas treatment... staff atau nurse plak friendly je... pandai melawak noh... kalau star tu boleh bg 10... 10 rasa nye nk bg... 🙊🙊...pas nyh kene repeat sini balik...tapi tggu 6 bulan lagi la...",
          client: "Aini Azura",
        },
        {
          p: "Doctor (Dr Ehsan) and the staffs very friendly...Waiting period within 5mins only...Explanations given nicely when we asking...Will recommend Idramawar Dental Clinic to my fellow friends and family...Thumbs up!!",
          client: "Ainur Fateha",
        },
        {
          p: " Terima kasih doctor noreen, dah 3tahun gigi depan saya separuh je sebab accident 2019 dulu. Sekarang dah ditampal dengan sepenuhnya",
          client: "Manja Shasya",
        },
      ],
      faq: [
        {
          q: "faq_1",
          a: "faq_1_p",
        },

        {
          q: "faq_2",
          a: "faq_2_p",
        },
        {
          q: "faq_3",
          a: "faq_3_p",
        },
        {
          q: "faq_4",
          a: "faq_4_p",
        },
        {
          q: "faq_5",
          a: "faq_5_p",
        },
        {
          q: "faq_6",
          a: "faq_6_p",
        },
        {
          q: "faq_7",
          a: "faq_7_p",
        },
        {
          q: "faq_8",
          a: "faq_8_p",
        },
      ],
      gallery: [
        "/images/gallery-1.jpg",
        "/images/gallery-2.jpg",
        "/images/gallery-3.jpg",
        "/images/gallery-4.jpg",
        "/images/gallery-5.jpg",
        "/images/gallery-6.jpg",
        "/images/gallery-7.jpg",
        "/images/gallery-8.jpg",
        "/images/gallery-9.jpg",
      ],
    };
  },
};
</script>

<style>
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  transition: 0.5s ease;
  background-color: #0b2153;
}

.text {
  position: absolute;
  /* top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  /* text-align: center; */
}

.custom:hover .overlay {
  opacity: 0.9;
}

#element iframe {
  min-height: 560px !important;
}
</style>
