<template>
  <div>
    <!-- Main Banner -->
    <MainBanner hide-enquiry="true" />
    <!-- end Main Banner -->
    <div class="container py-8 mx-auto text-center">
      <h1 class="pb-6 text-3xl text-teeth-darkblue md:text-4xl">
        {{ $t('thank_you_tagline') }}
      </h1>
      <p class="px-4 text-lg md:px-0 opensan400">
        {{ $t('thank_you_1') }}
      </p>
      <router-link :to="{ name: 'home', params: { locale: $i18n.locale } }" class="block w-48 mx-auto">
        <p class="block py-2 my-5 text-white rounded-lg bg-teeth-darkblue">
          {{ $t('thank_you_2') }}
        </p>
      </router-link>
    </div>
    <!-- copyRight -->
  </div>
</template>

<script>
import MainBanner from "@/components/MainBanner.vue";

export default {
  components: {
    MainBanner,
  },
};
</script>

<style>
</style>