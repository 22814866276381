<template>
  <div class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/main-bg.jpg')">

    <!-- lang select -->
    <div>
      <div class="container">
        <div class="flex justify-end text-lg text-white">
          <p>{{ $t('lang_select') }} :</p>
          <div class="flex px-2">
            <router-link :to="{ name: 'home', params: { locale: 'en' } }" :class="{ 'active': $i18n.locale === 'en' }">English</router-link>
            <div class="px-2">|</div>
            <router-link :to="{ name: 'home', params: { locale: 'my' } }" :class="{ 'active': $i18n.locale === 'my' }">Bahasa Malaysia</router-link>
          </div>
          <!-- <div class="pl-4 font-normal text-gray-900">
            <select v-model="$i18n.locale" class="px-2">
              <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
                {{ lang }}
              </option>
            </select>
          </div> -->
        </div>
      </div>
    </div>
    <div class="container lg:pt-10">
      <div class="lg:flex lg:relative lg:items-center lg:pt-10">
        <div class="pt-16 lg:absolute lg:pt-0 lg:w-1/2">
          <img src="/images/main-logo-white.png" alt="" class="w-auto" />
          <h1 class="text-4xl font-bold text-white">
            {{ $t('banner_title_1') }}
          </h1>
          <h1 class="text-5xl font-bold text-white md:text-7xl">
            <span class="text-teeth-blue">{{ $t('banner_title_2') }}</span> {{ $t('banner_title_3') }}
          </h1>
          <p class="mt-6 text-lg leading-tight text-gray-50">{{ $t('tagline_1') }} <br class="hidden md:block"> {{
            $t('tagline_2') }}</p>

          <!-- cta button -->
          <div v-if="!hideEnquiry" class="flex pt-6 text-center">
            <a href="https://wa.me/60125281769?text=I%20would%20like%20to%20make%20an%20appointment/know%20more%20about%20your%20services."
              class="pr-1">
              <h1
                class="py-1 text-white transition duration-300 ease-linear rounded-md w-44 lg:text-lg hover:bg-teeth-darkblue bg-teeth-blue">
                {{ $t('btn_consult') }}
              </h1>
            </a>
            <a href="https://goo.gl/maps/8UsH8gWjDtiDjKuF6" target="_blank" class="">
              <h1
                class="py-1 text-white transition duration-300 ease-linear rounded-md w-44 lg:text-lg hover:bg-teeth-darkblue bg-teeth-blue">
                {{ $t('btn_locate') }}
              </h1>
            </a>
          </div>
        </div>
        <!-- <div class="lg:w-3/4"> -->
        <img src="/images/img-doc.png" alt=""
          class="w-full pt-3 md:w-1/2 md:mx-auto lg:mx-0 lg:w-1/2 xl:w-2/5 lg:ml-auto" />
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeLocale(locale) {
      console.log('Changing locale to:', locale);
      this.$i18n.locale = locale;
      this.$router.push({ name: 'home', params: { locale } });
    }
  }
  // name: 'locale-changer',
  // data() {
  //   return { langs: ['en', 'my'] }
  // }
};
</script>

<style>
.active {
  color: rgba(255, 0, 0, 0.736); 
}
</style>